@import "/src/asset/styles/fonts";
@import "/src/asset/styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@font-face {
  font-family: "Montserrat";
  src: url("./asset/fonts/Montserrat-Regular.eot");
  src: url("./asset/fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./asset/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./asset/fonts/Montserrat-Regular.woff") format("woff"),
    url("./asset/fonts/Montserrat-Regular.ttf") format("truetype"),
    url("./asset/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@import url("https://fonts.googleapis.com/css2?family=Amiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Roboto&family=Roboto+Slab:wght@300;400&display=swap");
@media print {
  @page {
    size: portrait;
  }
}
.MuiGrid-grid-xs-12 {
  @media print {
    max-width: 25% !important;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
  @media print {
    width: 100%;
    height: 100%;
    font-size: 10px;
  }
}
.Id_image {
  margin: 2rem auto;
  width: 60%;
}
.mui-rtl-e22v62-MuiModal-root-MuiDialog-root
  .MuiDialog-container
  .MuiPaper-root {
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}
.mui-rtl-aim61a {
  margin-top: 10px;
}
.idImageContainer {
  page-break-before: always;
}
* {
  @media print {
    font-size: 10px;
  }
}

:dir(rtl) {
  body,
  *,
  span {
    font-family: "Tajawal", sans-serif !important;
  }
  p {
    font-family: "Tajawal", sans-serif !important;
    text-align: start;
  }
  input {
    font-family: "Tajawal", sans-serif !important;
    &::placeholder {
      font-family: "Tajawal", sans-serif !important;
    }
  }
}
:dir(ltr) {
  * {
    font-family: "Montserrat", sans-serif !important;
  }
}
.SideNav {
  width: 22vw;
  overflow: hidden;

  @media print {
    display: none;
  }
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 600px) {
    height: 5vh;
    width: 100%;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;

    img {
      width: 50vw;
    }
  }
  background-color: $Primary;
  height: 100vh;
  position: relative;
  inset-inline-start: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .phoneIcon {
    @media screen and (min-width: 600px) {
      display: none;
    }
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  .text {
    @media screen and (max-width: 600px) {
      display: none;
    }
    width: 100%;
    color: white;
    height: 30%;
    padding: 10px 40px;
    h3 {
      width: 70%;
      white-space: pre-wrap;
      @media screen and (max-width: 600px) {
        width: auto;
        white-space: initial;
      }
    }
    p {
      margin-top: 20px;
      width: 85%;
      white-space: pre-wrap;
      @media screen and (max-width: 600px) {
        width: auto;
        margin-top: 0px;
        white-space: initial;
      }
    }
  }
}
.SnackbarContainer-root {
  @media print {
    display: none !important;
  }
}

.pageBreak {
  page-break-before: always;
}
.MuiBox-root {
  margin-top: 5px !important;
}

@page {
  padding: 10px !important;
  margin: 1cm !important;
}

.mat-snack-bar-container {
  @media print {
    display: none !important;
  }
}

.printBox {
  padding: 15px 20px;
}
.LogoImg {
  width: 40%;
}
.btnSubmit {
  @media print {
    display: none !important;
  }
}
.home {
  position: fixed;
  top: -40px !important;
  @media screen and (max-width: 600px) {
    top: 10px !important;
  }
  button {
    @media screen and (max-width: 600px) {
      padding: 0px !important;
      width: 100%;
    }
  }
}
.mobileNav {
  @media screen and (min-width: 600px) {
    display: none;
  }
  button {
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0px;
    padding: 0px;
  }
}

.nav {
  cursor: pointer;
  position: relative;
  top: -30px;
  margin-inline-start: 22vw;
  width: 78%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: white;
  @media print {
    display: none;
  }
  .langBtn {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  button {
    background-color: white;
    color: $Primary;
    border: none;
    outline: none;
    border-radius: 10px;
    margin: 1vw 5vw;
    padding: 5px 10px;
    &:hover {
      background: #ececec;
    }
    @media screen and (max-width: 600px) {
      background-color: transparent;
      // margin: -3.7vh 0vh;
      z-index: 60000;
      font-size: 11px;
      margin-inline-start: 0px;
      margin: 0px;
      padding: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    top: -40px;
    width: 100%;
    height: 5vh;
    align-items: center;
    margin-inline-start: 0px;
  }
}
.en {
  &:before {
    left: 19vw;
    right: 0px !important;
  }
  &::after {
    left: 27vw;
    right: 0px !important;
  }
}
input:disabled {
  color: #081937;
  font-family: "Tajawal", sans-serif;
}
input::placeholder {
  font-family: "Tajawal", sans-serif;
}
.MuiDialog-paperWidthLg {
  @media screen and (max-width: 700px) {
    width: 90vw !important ;
  }
}
.red {
  background: "red" !important;
  @media screen and (max-width: 800px) {
    width: 90vw !important ;
  }
}
.bg {
  position: relative;
  &:before {
    content: " ";
    background: #ec1e32;
    position: fixed;
    width: 30vw;
    bottom: -50px;
    right: 20vw;
    height: 80vh;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &::after {
    content: " ";
    border-radius: 16px 16px 0px 0px;
    background: #f9b700;
    position: fixed;
    width: 30vw;
    bottom: -50px;
    right: 26vw;
    height: 60vh;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
.mui-rtl-1xghtyc-MuiButtonBase-root-MuiButton-root {
  color: #f5f5fa !important;
}
input::-webkit-file-upload-button {
  visibility: hidden;
}
.MuiFormHelperText-root.Mui-error {
  text-align: start !important;
  font-family: "Tajawal", sans-serif !important;
  letter-spacing: 0.3px !important;
}
.MuiFormHelperText-root {
  font-family: "Tajawal", sans-serif !important;
  letter-spacing: 0.3px !important;
}
